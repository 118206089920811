
































































































































































































































































































































































































import { Component, Prop, Ref, Watch } from "vue-property-decorator";

import FormInputField from "@/components/form-components/FormInputField.vue";
import FormCheckboxField from "@/components/form-components/FormCheckboxField.vue";
import djlMultiSelect from "@/components/form-components/djlMultiselect.vue";
import DjlMultiselect from "@/components/form-components/djlMultiselect.vue";
import djlDatepicker from "@/components/form-components/djlDatepicker.vue";
import djlFileUpload from "@/components/form-components/djlFileUpload.vue";
import VForm from "@/components/VForm.vue";
import formatISO from "date-fns/formatISO";
import addDays from "date-fns/addDays";

import { DOCUMENTS_ACTIONS, NewDocument } from "@/store/modules/documents";
import { getYear } from "date-fns";
import { Account } from "@/interfaces/Account";
import accessControl from "@/api/access_control";
import { Employee } from "@/models/employee";
import { Department, departments, DocumentPeriod } from "@/models/document";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import IDocumentFormData, {
  INexusRecipient,
} from "@/interfaces/IDocumentFormData";
import { TaskCategory } from "@/models/task";
import DocumentProperties from "@/service/DocumentProperties";
import { DocumentType } from "@/enums/DocumentType";
import { DocumentDiscipline } from "@/enums/DocumentDiscipline";
import { AxiosResponse } from "axios";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";
import { accountLabel } from "@/utils/accountDisplayFormatter";
import { VATNotFoundProblem } from "@/api/portal/taskProblemInterceptor";
import { NexusRecipient, nexusRecipients } from "@/enums/NexusRecipient";

interface TranslatedDocumentType {
  key: DocumentType;
  value: string;
}

enum FormFieldEnum {
  DOCUMENT_TYPE_MULTISELECT,
  DISCIPLINE_MULTISELECT,
  FILE_INPUT,
  VAT_FISCAL_ENTITY_DIVISION_INPUT,
  NEXUS_RECIPIENTS_MULTISELECT,
  EMPLOYEE_MULTISELECT,
  EDITOR_MULTISELECT,
  DOCUMENT_PERIOD_TYPE_MULTISELECT,
  DOCUMENT_PERIOD_INPUT_FIELDS,
  END_DATE_INPUT,
  DEPARTMENT_MULTISELECT,
}

@Component({
  methods: { accountLabel },
  components: {
    DjlMultiselect,
    VForm,
    djlMultiSelect,
    djlDatepicker,
    djlFileUpload,
    FormInputField,
    FormCheckboxField,
    TheHeader,
    Page,
  },
})
@Translatable("tasks")
export default class TasksCreate extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  private messages: { [key: string]: string } = {};
  private formLoading: boolean = false;
  private now: Date = new Date(Date.now());
  private possibleDocumentPeriodTypes: DocumentPeriod[] = [];
  private documentTypes: TranslatedDocumentType[] = [];

  private input: IDocumentFormData = {
    title: "",
    documentType: null,
    discipline: null,
    account: null,
    department: "accountants_en_advies",
    signer: null,
    sender: null,
    editor: null,
    nexusRecipient: null,
    vatFiscalEntityDivision: "",
    files: [],
    period: {
      type: null,
      year: getYear(Date.now()).toString(),
      month: null,
      quarter: "null",
      startMonth: null,
    },
    endDate: null,
  };

  @Ref() readonly accountMultiselect!: djlMultiSelect;
  @Ref() readonly documentPeriodTypeMultiselect!: djlMultiSelect;
  @Ref() readonly nexusRecipientsMultiSelect!: djlMultiSelect;
  @Ref() readonly employeeMultiselect!: djlMultiSelect;
  @Ref() readonly editorMultiselect!: djlMultiSelect;
  @Ref() readonly fileUpload!: djlFileUpload;
  @Ref() readonly documentTypeMultiselect!: djlMultiSelect;
  @Ref() readonly disciplineMultiSelect!: djlMultiSelect;

  @Prop({ default: "my" })
  private category!: TaskCategory;

  private accountList: Account[] = [];
  private employeeGenericList: Employee[] = [];
  private employeeEditorList: Employee[] = [];
  private documentProperties!: DocumentProperties;

  private selectedDocumentType: TranslatedDocumentType | null = null;

  get taskCategory(): TaskCategory {
    return this.category;
  }

  public get isObLegacy(): boolean {
    return process.env.VUE_APP_OB_LEGACY?.toLowerCase() === "true";
  }

  private get hasAttachments(): boolean {
    return (
      !this.isObLegacy ||
      !(
        this.input.documentType === DocumentType.OMZETBELASTING ||
        this.input.documentType === DocumentType.OMZETBELASTING_SUPPLETIE
      )
    );
  }

  private get needsEditor(): boolean {
    return (
      this.isObLegacy &&
      (this.input.documentType === DocumentType.OMZETBELASTING ||
        this.input.documentType === DocumentType.OMZETBELASTING_SUPPLETIE)
    );
  }

  private get needsSigner(): boolean {
    return this.input.documentType !== DocumentType.CONCEPT_JAARREKENING;
  }

  private get needsSender(): boolean {
    return !this.needsSigner;
  }

  private get hasEndDateField(): boolean {
    return !(this.input === null
      ? true
      : this.input.documentType === DocumentType.VENNOOTSCHAPSBELASTING ||
        this.input.documentType === DocumentType.INKOMSTENBELASTING ||
        this.input.documentType === DocumentType.JAARREKENING_MICRO ||
        this.input.documentType === DocumentType.JAARREKENING_KLEIN ||
        this.input.documentType === DocumentType.JAARREKENING_MIDDELGROOT ||
        this.input.documentType === DocumentType.JAARREKENING_GROOT ||
        this.input.documentType === DocumentType.ICP ||
        this.input.documentType === DocumentType.OMZETBELASTING ||
        this.input.documentType === DocumentType.OMZETBELASTING_SUPPLETIE);
  }

  private get isKredietRapportage(): boolean {
    return (
      this.input.documentType === DocumentType.KREDIETRAPPORTAGE_BEPERKT ||
      this.input.documentType === DocumentType.KREDIETRAPPORTAGE_MIDDELGROOT ||
      this.input.documentType === DocumentType.KREDIETRAPPORTAGE_PERSOON
    );
  }

  private get departments(): Department[] {
    return departments.map((dep) => dep);
  }

  private departmentLabel(dep: Department) {
    return this.$t(`models.department.${dep}`);
  }

  @Watch("selectedDocumentType")
  private changeDocumentTypeInput(type: TranslatedDocumentType) {
    this.input.documentType = type?.key || null;
  }

  private get documentLabel(): string {
    return this.isObLegacy && this.input.documentType === DocumentType.ICP
      ? "Selecteer CSV-bestand"
      : "Selecteer bestand(en)";
  }

  get documentTypeIsSelected(): boolean {
    return this.input.documentType !== null;
  }

  get isAccountSelected(): boolean {
    return !!this.input.account?.id;
  }

  private get acceptedFileTypes(): string | null {
    const acceptedBaseTypes = ".pdf,.xbrl,.xml,.csv";
    switch (this.input.documentType) {
      case DocumentType.OMZETBELASTING:
      case DocumentType.OMZETBELASTING_SUPPLETIE:
      case DocumentType.JAARREKENING_MICRO:
      case DocumentType.JAARREKENING_KLEIN:
      case DocumentType.JAARREKENING_MIDDELGROOT:
      case DocumentType.JAARREKENING_GROOT:
        return acceptedBaseTypes + ",.XBRL";
      default:
        return acceptedBaseTypes;
    }
  }

  private get isDisabled(): boolean {
    return this.documentTypes.length < 1 || this.accountList.length < 1;
  }

  private months = [
    { name: "Januari", value: "01" },
    { name: "Februari", value: "02" },
    { name: "Maart", value: "03" },
    { name: "April", value: "04" },
    { name: "Mei", value: "05" },
    { name: "Juni", value: "06" },
    { name: "Juli", value: "07" },
    { name: "Augustus", value: "08" },
    { name: "September", value: "09" },
    { name: "Oktober", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  private bimonths = [
    { name: "Januari-februari", value: "01" },
    { name: "Februari-maart", value: "02" },
    { name: "Maart-april", value: "03" },
    { name: "April-mei", value: "04" },
    { name: "Mei-juni", value: "05" },
    { name: "Juni-juli", value: "06" },
    { name: "Juli-augustus", value: "07" },
    { name: "Augustus-september", value: "08" },
    { name: "September-oktober", value: "09" },
    { name: "Oktober-november", value: "10" },
    { name: "November-december", value: "11" },
    { name: "December-januari", value: "12" },
  ];

  private get disciplines(): DocumentDiscipline[] {
    return Object.values(DocumentDiscipline);
  }

  mounted() {
    this.documentProperties = DocumentProperties.getInstance();

    accessControl.accounts
      .accounts()
      .then((response: AxiosResponse<Account[]>) => {
        this.accountList = response.data;
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_api_value("access_control.error.fetch_accounts")
        );
        this.accountList = [];
      });
  }

  private documentPeriodLabel(period: DocumentPeriod) {
    return this.model_human_attribute_value("DocumentPeriod", period);
  }

  private disciplineLabel(discipline: DocumentDiscipline) {
    return this.model_human_attribute_value("DocumentDiscipline", discipline);
  }

  private setExpirationDateFromDocumentType(
    documentSelectOption: DocumentType
  ) {
    return (this.input.endDate = addDays(
      this.now,
      this.documentProperties.for(documentSelectOption)?.defaultExpiresInDays ||
        0
    ));
  }

  private prepareInputForBackend(): NewDocument {
    const {
      title,
      account,
      department,
      documentType,
      discipline,
      signer,
      sender,
      editor,
      endDate,
      files,
      nexusRecipient,
      vatFiscalEntityDivision,
    } = this.input;

    let output: NewDocument = {
      title,
      department,
      documentType,
      discipline,
      accountId: account?.id || "",
      period: this.formatPeriod(this.input),
      endDate:
        endDate !== null
          ? formatISO(endDate, {
              representation: "date",
            })
          : null,
      nexusRecipient: nexusRecipient?.value || "",
      vatFiscalEntityDivision,
      signer: signer?.id || "",
      sender: sender?.id || "",
      editor: editor?.id || "",
    };

    if (this.input.files.length > 0) {
      output["files"] = Array.from(files);
    }

    return output;
  }

  private formatPeriod(input: IDocumentFormData): string {
    switch (input.period.type) {
      case "MONTH":
        return `${input.period.year}-M${input.period.month?.value}`;
      case "BIMONTHLY":
        return `${input.period.year}-2M${input.period.month?.value}`;
      case "QUARTER":
        return `${input.period.year}-${input.period.quarter}`;
      case "QUARTER_FINANCIAL_YEAR":
        return `${input.period.year}-${input.period.quarter}-M${input.period.startMonth?.value}`;
      case "CALENDAR_YEAR":
        return input.period.year;
      case "FINANCIAL_YEAR":
        return `${input.period.year}-${parseInt(input.period.year) + 1}-M${
          input.period.month?.value
        }`;
      default:
        return "";
    }
  }

  private cancelClicked(event: Event): void {
    this.$router.push({
      name: "documents",
      query: { category: this.taskCategory },
    });
  }

  @Watch("input.documentType")
  private documentTypeChanged(documentTypeSelectOption: DocumentType) {
    if (!documentTypeSelectOption) return;

    this.resetFormField(FormFieldEnum.DISCIPLINE_MULTISELECT);
    this.resetFormField(FormFieldEnum.FILE_INPUT);
    this.resetFormField(FormFieldEnum.VAT_FISCAL_ENTITY_DIVISION_INPUT);
    this.resetFormField(FormFieldEnum.NEXUS_RECIPIENTS_MULTISELECT);
    this.resetFormField(FormFieldEnum.EMPLOYEE_MULTISELECT);
    this.resetFormField(FormFieldEnum.EDITOR_MULTISELECT);
    this.resetFormField(FormFieldEnum.DOCUMENT_PERIOD_TYPE_MULTISELECT);
    this.resetFormField(FormFieldEnum.END_DATE_INPUT);
    this.resetFormField(FormFieldEnum.DEPARTMENT_MULTISELECT);

    this.input.documentType = documentTypeSelectOption;

    this.possibleDocumentPeriodTypes =
      this.documentProperties.for(documentTypeSelectOption)?.period || [];

    if (this.possibleDocumentPeriodTypes.length === 1) {
      //preselect the Period Type if there is only one.
      this.input.period.type = this.possibleDocumentPeriodTypes[0];
    }

    if (this.hasEndDateField) {
      this.setExpirationDateFromDocumentType(documentTypeSelectOption);
    }

    this.findEmployees(this.input.account, this.input.documentType);
  }

  @Watch("input.period.type")
  private documentPeriodChanged() {
    this.resetFormField(FormFieldEnum.DOCUMENT_PERIOD_INPUT_FIELDS);
  }

  @Watch("input.account")
  private selectedAccountChanged(account: Account) {
    this.resetFormField(FormFieldEnum.DOCUMENT_TYPE_MULTISELECT);
    this.resetFormField(FormFieldEnum.DISCIPLINE_MULTISELECT);
    this.resetFormField(FormFieldEnum.FILE_INPUT);
    this.resetFormField(FormFieldEnum.VAT_FISCAL_ENTITY_DIVISION_INPUT);
    this.resetFormField(FormFieldEnum.NEXUS_RECIPIENTS_MULTISELECT);
    this.resetFormField(FormFieldEnum.EMPLOYEE_MULTISELECT);
    this.resetFormField(FormFieldEnum.EDITOR_MULTISELECT);
    this.resetFormField(FormFieldEnum.DOCUMENT_PERIOD_TYPE_MULTISELECT);
    this.resetFormField(FormFieldEnum.END_DATE_INPUT);
    this.resetFormField(FormFieldEnum.DEPARTMENT_MULTISELECT);

    this.documentTypes = this.documentProperties
      .getDocumentTypesByAccountType(account.type)
      .map((value) => {
        return {
          key: value,
          value: this.model_human_attribute_value(
            "DocumentType",
            value
          ).toString(),
        };
      });
  }

  get translatedNexusRecipients(): INexusRecipient[] {
    return nexusRecipients.map((value) => {
      return {
        name: this.model_human_attribute_value("NexusRecipient", value),
        value: value,
      };
    });
  }

  private findEmployees(account: Account | null, documentType: DocumentType) {
    if (account === null) return;
    accessControl.accounts
      .accountEmployeesSigner(account.id, documentType)
      .then(({ data }) => {
        this.employeeGenericList = data;
      })
      .catch(() => {
        this.$snotify.error("Kan de medewerkers voor het account niet ophalen");
      });

    if (this.needsEditor) {
      accessControl.accounts
        .accountEmployees(account.id)
        .then(({ data }) => {
          this.employeeEditorList = data;
        })
        .catch(() => {
          this.$snotify.error(
            "Kan de medewerkers voor het account niet ophalen"
          );
        });
    }
  }

  private createDocument(event: Event): Promise<any> {
    return new Promise((resolve, reject) => {
      this.formLoading = true;
      this.$store
        .dispatch(
          DOCUMENTS_ACTIONS.addNewDocument,
          this.prepareInputForBackend()
        )
        .then((response) => {
          this.$snotify.success("Document succesvol aangemaakt.");
          this.formLoading = false;
          const documentId = response.data.id;
          this.$router.push({
            name: "documents-show",
            params: { documentId },
            query: { category: this.taskCategory },
          });
          resolve(documentId);
        })
        .catch((reason: unknown) => {
          if (reason instanceof VATNotFoundProblem) {
            this.$snotify.error(
              this.translated_api_value("portal.problems.vatnot_found_problem")
            );
          } else {
            this.$snotify.error("Er is iets fout gegaan.");
          }
          this.formLoading = false;
          reject(reason);
        });
    });
  }

  private resetFormField(formFieldName: FormFieldEnum) {
    const reinforcedSwitch: Record<FormFieldEnum, () => void> = {
      [FormFieldEnum.DOCUMENT_TYPE_MULTISELECT]: () => {
        this.documentTypeMultiselect?.resetValue();
        this.input.documentType = null;
      },
      [FormFieldEnum.DISCIPLINE_MULTISELECT]: () => {
        this.disciplineMultiSelect?.resetValue();
        this.input.discipline = null;
      },
      [FormFieldEnum.FILE_INPUT]: () => {
        this.fileUpload?.resetValue();
        this.input.files = [];
      },
      [FormFieldEnum.VAT_FISCAL_ENTITY_DIVISION_INPUT]: () => {
        this.input.vatFiscalEntityDivision = "";
      },
      [FormFieldEnum.NEXUS_RECIPIENTS_MULTISELECT]: () => {
        this.nexusRecipientsMultiSelect?.resetValue();
        this.input.nexusRecipient = null;
      },
      [FormFieldEnum.EMPLOYEE_MULTISELECT]: () => {
        this.employeeMultiselect?.resetValue();
        this.input.signer = null;
        this.input.sender = null;
        this.employeeGenericList = [];
      },
      [FormFieldEnum.EDITOR_MULTISELECT]: () => {
        this.editorMultiselect?.resetValue();
        this.input.editor = null;
        this.employeeEditorList = [];
      },
      [FormFieldEnum.DOCUMENT_PERIOD_TYPE_MULTISELECT]: () => {
        this.documentPeriodTypeMultiselect?.resetValue();
        this.input.period.type = null;

        this.resetFormField(FormFieldEnum.DOCUMENT_PERIOD_INPUT_FIELDS);
      },
      [FormFieldEnum.DOCUMENT_PERIOD_INPUT_FIELDS]: () => {
        this.input.period.month = null;
        this.input.period.quarter = "";
        this.input.period.year = getYear(Date.now()).toString();
        this.input.period.startMonth = null;
      },
      [FormFieldEnum.END_DATE_INPUT]: () => {
        this.input.endDate = null;
      },
      [FormFieldEnum.DEPARTMENT_MULTISELECT]: () => {
        this.input.department = "accountants_en_advies";
      },
    };

    return reinforcedSwitch[formFieldName]();
  }

  private get DocumentType(): typeof DocumentType {
    return DocumentType;
  }
}
